import Swiper from 'swiper';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function() {
    var gallerySlide = new Swiper(".gallery-slide", {
        slidesPerView: 2,
        spaceBetween: 20,
        breakpoints: {
            1200: {
              slidesPerView: 3,
            }
        }
    });

    var postSlide = new Swiper(".index-post-list", {
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
                return '<button type="button" class="' + className + '">' + index + '</button>';
            }
        },
        modules: [Navigation, Pagination],
        breakpoints: {
            576: {
              slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1200: {
                slidesPerView: 4
            }
        }
    });

    $(".slick-next").on("click", function(){
        gallerySlide.slideNext();
    })

    //無障礙鍵盤控制
    if($("#indexPostList").length > 0){
        const indexPost = document.getElementById("indexPostList");
        const allIndexPosts = indexPost.querySelectorAll('.swiper-slide');

        indexPost.addEventListener('keydown', function(event) {
            if (event.key === 'ArrowLeft') {
                postSlide.slidePrev();
            }else if(event.key === 'ArrowRight' || event.key === 'Tab'){
                postSlide.slideNext();
            }
        });
    }

    if($("#relItem").length > 0){
        new Swiper("#relItem", {
            slidesPerView: 2,
            spaceBetween: 20,
            breakpoints: {
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 4
                }
            }
        });
    }
});